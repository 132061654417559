import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Stack,
  Chip,
} from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

export default function COM_AutoComplte({
  p_title,
  p_data,
  p_Menu_ObjKey,
  p_selectedObj,
  p_setSelectedObj,
}) {
  // ----------------  Global Variable
  const [inputValue, setInputValue] = useState("");

  const options = p_data.map((option) => {
    const firstLetter = option[p_Menu_ObjKey][0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const filter = createFilterOptions();

  // ----------------------- dialog
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      year: "",
    });

    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: "",
    year: "",
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    p_setSelectedObj({
      title: dialogValue.title,
      year: parseInt(dialogValue.year, 10),
    });

    handleClose();
  };

  return (
    <div>
      <Autocomplete
        // value={p_selectedValue}
        onChange={(event, newValue) => {
          if (newValue != null) {
            p_setSelectedObj(newValue[p_Menu_ObjKey]);
            console.log("p_setSelectedObj", newValue[p_Menu_ObjKey]);
            p_setSelectedObj(newValue);
            console.log("p_setSelectedObj", newValue);
          }
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          if (newInputValue != "undefined") {
            setInputValue(newInputValue);
            console.log("inputValue", newInputValue);
          }
        }}
        id="controllable-states-demo"
        options={options.sort(
          (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
        )}
        groupBy={(option) => option.firstLetter}
        getOptionLabel={(option) => option[p_Menu_ObjKey]}
        sx={{ width: 300 }}
        renderInput={(params) => <TextField {...params} label={p_title} />}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            {option[p_Menu_ObjKey]}
          </Box>
        )}
        renderOption={(props, option, { inputValue }) => {
          const matches = match(option[p_Menu_ObjKey], inputValue);
          const parts = parse(option[p_Menu_ObjKey], matches);

          return (
            <li {...props}>
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                      color: part.highlight ? "orange" : "black",
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </li>
          );
        }}
      />
    </div>
  );
}
