import react, { useEffect, useState, useRef } from "react";
import moment from "moment";

import axios from "axios";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";
import MaterialTable from "material-table";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({}) {
  // ------------------------------------------------- DB

  // get eventSetting
  const show_game_num = useRef();
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        setEventSetting(res.data[0]);
        show_game_num.current = res.data[0].show_game_num;
        getDB_userScore();
      });
  };

  // get user score
  const [userScore, setUserScore] = useState([]);
  const getDB_userScore = async () => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_score?game_num=${show_game_num.current}`
      )
      .then((res) => {
        // console.log("userScore", res.data[1]);
        setUserScore(res.data[1]);
      });
  };
  //  -------------------------- Table column
  // ------------------------------------------------- DB
  const [state, setState] = useState({
    columns_userScore: [
      // {
      //   title: "No",
      //   field: "num",
      //   width: "1px",
      // },
      {
        title: "Steam Name",
        field: "user_steamname",
        width: "1px",
        render: (rowData) => (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>#{rowData.num}</div>
              <img
                src={rowData.user_profile_pic_url}
                style={{ borderRadius: "30px", height: "30px" }}
              />

              <div> {rowData.user_steamname}</div>
            </div>
          </>
        ),
      },
      {
        title: "Score",
        field: "total_score",
        width: "1px",
      },
    ],
  });

  // ------------------------------------- Global Variable

  const [sec, setSec] = useState();
  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    setSec(d.seconds());
  };

  useEffect(() => {
    // getDB_userScore();
    getDB_eventSetting();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      getDB_eventSetting();
      DateTimeCounter();
      // getDB_userScore();
    }, 1000 * 60 * 1); // 5min update 1
  }, [sec]);

  return (
    <>
      <MaterialTable
        style={{
          color: "white",
          background: "rgba(255, 255, 255, .3)",

          boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
          backdropFilter: "blur( 11.0px )",
          "&::webkit-backdrop-filter": {
            webkitBackdropFilter: "blur( 18.0px )",
          },
        }}
        title={`Score Board  `}
        columns={state.columns_userScore}
        data={userScore}
        options={{
          // headerStyle: {
          //   background: "rgba(255, 255, 255, .2)",
          //   border: "none",
          // },

          // exportButton: true,
          // rowStyle: (rowData) => ({
          //   backgroundColor:
          //     rowData.Item_stock_balance < 0
          //       ? "#ff0000"
          //       : rowData.Item_stock_balance === 0
          //       ? "#1a0000"
          //       : "",
          //   color:
          //     rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
          //   // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
          //   fontSize: 12,
          // }),
          // filtering: true,
          // grouping: true,
          // exportAllData: true,
          pageSize: 3,
          pageSizeOptions: [3, 10, 20],
          paginationType: "stepped",
          showFirstLastPageButtons: false,
          search: false,
          // showTitle: false,
          header: false,
          // actionsColumnIndex: -1
          // fixedColumns: {
          //     left: 1,
          // }
        }}
        onRowClick={(evt, selectedRow) => {
          // alert(selectedRow.id);
        }}
        actions={
          [
            // {
            //   icon: "save",
            //   tooltip: "Save User",
            //   onClick: (event, rowData) => {
            //     handleClickOpen_1_1();
            //     // getDB_patroninfo_byid(rowData.id);
            //     // alert(rowData.id);
            //   },
            //   onClick2: (event, rowData) => {
            //     handleClickOpen_1_2();
            //     // getDB_patroninfo_byid(rowData.id);
            //     // alert(rowData.id);
            //   },
            // },
          ]
        }
        components={
          {
            // Action: (props) => (
            //   <>
            //     <Button
            //       // classes={{ root: classes.button }}
            //       onClick={(event) => props.action.onClick(event, props.data)}
            //     >
            //       EDIT
            //     </Button>
            //     <Button
            //       // classes={{ root: classes.button }}
            //       onClick={(event) => props.action.onClick2(event, props.data)}
            //     >
            //       <div style={{ color: "#2bc3ff" }}>MANAGE</div>
            //     </Button>
            //   </>
            // ),
          }
        }
      />
    </>
  );
}
