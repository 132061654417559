import react, { useEffect, useState, useRef, useCallback } from "react";
import moment from "moment";

import axios from "axios";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";
import MaterialTable from "material-table";
import { display } from "@mui/system";

import Page_TimerCountDown from "./Page_TimerCountDown";
// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({
  db_u_id,
  bid_ItemNo,
  bid_ItemSet,
  bid_minAmount_eachTime,
  timerCounter_Until,
}) {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  // ------------------------------------------------- DB

  // get user info by uid
  const [firebaseAPI_DB_users, setFirebaseAPI_DB_users] = useState([]);
  const getFirebaseAPI_DB_users_List = async (user_login_uid) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setFirebaseAPI_DB_users(res.data[0]);
        // console.log("getFirebaseAPI_DB_users_List", res.data[0]);
        if (res.data[0] != undefined) {
          // that is registered user
          setRegisteredUserStatus(true);
          // // get the user data
          // setDb_u_id(res.data[0].id);
          // setDeliveryAdd(res.data[0].user_deliveryadd);
          // setPostCode(res.data[0].user_postcode);
          // setCity(res.data[0].user_city);
          // setContactNo(res.data[0].user_contactno);
          // setSteamName(res.data[0].user_steamname);
          // setCb_User_event1_checkpoint(
          //   res.data[0]?.user_event1_checkpoint == "true" ? true : false
          // );
          // console.log("db chkp1 = ", res.data[0]?.user_event1_checkpoint);
        } else {
          setRegisteredUserStatus(false);
        }
      });
  };

  // get user bid
  const [userBid, setUserBid] = useState([]);
  const getDB_userBid = async () => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_bid?bid_ItemNo=${bid_ItemNo}&bid_ItemSet=${bid_ItemSet}`
      )
      .then((res) => {
        // console.log("userBid", res.data);
        // console.log("userBid", res.data[0]?.Total_Bid);
        setUserBid(res.data);

        setGlobal_Top1_Bid_Amount(res.data[0]?.Total_Bid);
        setGlobal_Top2_Bid_Amount(res.data[1]?.Total_Bid);
        setGlobal_Top3_Bid_Amount(res.data[2]?.Total_Bid);

        setMy_Highest_Bid_Amount(
          res.data.find((x) => x.u_id == db_u_id) != undefined
            ? res.data.find((x) => x.u_id == db_u_id).Total_Bid
            : "0"
        );
      });
  };

  // add user bid
  const setDB_userBid = async (
    u_id,
    user_bid_amount,
    bid_ItemNo,
    bid_ItemSet
  ) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__user_bid?u_id=${u_id}&user_bid_amount=${user_bid_amount}&bid_ItemNo=${bid_ItemNo}&bid_ItemSet=${bid_ItemSet}`
      )
      .then((res) => {
        // console.log("userBid", res.data);
      });
  };

  // get server datatime
  const [serverDatetime, setServerDatetime] = useState("");
  const getDB_serverDatetime = async (until) => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_serverDatetime?until=${until}`)
      .then((res) => {
        setServerDatetime(res.data[0]);
        // console.log("getDB_serverDatetime", res.data[0]);
      });
  };

  // get server datatime counterdown timer
  const [serverDatetime_Timer, setServerDatetime_Timer] = useState("");
  const getDB_serverDatetime_Timer = async (until) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_serverDatetime_countdowntimer?until=${until}`
      )
      .then((res) => {
        setServerDatetime_Timer(res.data[0]);
        // console.log("getDB_serverDatetime_Timer", res.data[0]);
      });
  };

  // ------------------------------------- time var

  const [sec, setSec] = useState();
  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    setSec(d.seconds());
  };

  //  -------------------------- Global Variable

  const [registeredUserStatus, setRegisteredUserStatus] = useState(false);

  const [global_Top1_Bid_Amount, setGlobal_Top1_Bid_Amount] = useState(0);
  const [global_Top2_Bid_Amount, setGlobal_Top2_Bid_Amount] = useState(0);
  const [global_Top3_Bid_Amount, setGlobal_Top3_Bid_Amount] = useState(0);

  const [my_Highest_Bid_Amount, setMy_Highest_Bid_Amount] = useState(0);

  const [my_Bid_Amount, setMy_Bid_Amount] = useState(0);

  const Timer_updateFromChild = (data) => {
    // alert(data);
    // console.log("data", data);
  };

  useEffect(() => {
    getDB_userBid();
    // getDB_serverDatetime_Timer(timerCounter_Until);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      DateTimeCounter();
      getDB_serverDatetime();
      getDB_serverDatetime_Timer(timerCounter_Until);
      getDB_userBid();
    }, 1000 * 10 * 1); // 5min update 1
  }, [sec]);

  // -------------------------------------------------  firebase - 3/3

  useEffect(() => {
    // console.log("#useEffect 1", firebase_userInfo?.displayName);
    // console.log("#useEffect2. ", firebase_userInfo);
    if (firebase_userInfo?.uid) {
      // console.log("a1", firebase_userInfo?.uid);
      // console.log("a1", firebase_userInfo?.email);
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);

      // nevigate to main page
      // window.location.href = "#/main";
    }
  }, [firebase_userInfo]);

  return (
    <>
      <br />
      <div style={{ display: "flex" }}>
        <img
          src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/now${bid_ItemNo}.png`}
          width="100%"
        ></img>
      </div>

      <div
        style={{
          backgroundColor: "#45AF49",
          width: "100%",
          padding: "5px 0 0 5px",
          fontSize: "12px",
        }}
      >
        <div style={{ color: "black", fontSize: "16px" }}>
          # Countdown {registeredUserStatus}
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}> {"System Time :"}</div>
          <div> {serverDatetime.datetimenow}</div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ width: "30%" }}> {"Finish Time :"}</div>
          <div> {timerCounter_Until}</div>
        </div>
        {/* <div
          style={{ display: "flex", gap: 5, width: "100%", fontSize: "30px" }}
        >
          {parseInt(serverDatetime_Timer.days) == 0 &&
          parseInt(serverDatetime_Timer.hours) == 0 &&
          parseInt(serverDatetime_Timer.minutes) == 0 ? (
            <div>Done</div>
          ) : (
            <>
             <div
                style={{
                  backgroundColor: "black",
                  borderRadius: "10px",
                  width: "20%",
                  textAlign: "center",
                  color: "white",
                  padding: "10px",
                }}
              >
                <div>{serverDatetime_Timer.days}</div>
                <div style={{ fontSize: "10px" }}>DAY</div>
              </div>  
              <div
                style={{
                  backgroundColor: "black",
                  borderRadius: "10px",
                  // width: "20%",
                  textAlign: "center",
                  color: "white",
                  padding: "5px",
                }}
              >
                <div>{serverDatetime_Timer.hours}</div>
                <div style={{ fontSize: "10px" }}>HOUR</div>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  borderRadius: "10px",
                  // width: "20%",
                  textAlign: "center",
                  color: "white",
                  padding: "5px",
                }}
              >
                <div>{serverDatetime_Timer.minutes}</div>
                <div style={{ fontSize: "10px" }}>MIN</div>
              </div>
              <div
                style={{
                  backgroundColor: "black",
                  borderRadius: "10px",
                  // width: "20%",
                  textAlign: "center",
                  color: "white",
                  padding: "5px",
                }}
              >
                <div>{serverDatetime_Timer.seconds}</div>
                <div style={{ fontSize: "10px" }}>SEC</div>
              </div>
            </>
          )}
        </div> */}
        <br />
        <br />
        <div style={{ color: "black", fontSize: "18px" }}> # TOP BID</div>
        <div style={{ backgroundColor: "gold", marginBottom: "2px" }}>
          {/* # Top 1 Bid Amount({Math.abs(sec % 2)}) */}
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={userBid[0]?.user_profile_pic_url}
                style={{ borderRadius: "30px", marginRight: "10px" }}
              ></img>
            </div>
            <div>
              <div
                style={{
                  color: "black",
                  fontSize: "16px",
                }}
              >
                {` ${userBid[0]?.user_login_name} `}
              </div>
              <div
                style={{
                  color: "gray",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                {`  ${userBid[0]?.bid_dt}`}
              </div>
            </div>
          </div>
          <div
            style={{
              color: "gold",
              backgroundColor: "black",
              fontSize: "16px",
              width: "100%",
            }}
          >{`RM ${
            global_Top1_Bid_Amount != 0 ? global_Top1_Bid_Amount : "loading..."
          }`}</div>
        </div>
        {/* <div style={{ backgroundColor: "lightgreen", marginBottom: "2px" }}>
          # Top 2 Bid Amount
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={userBid[1]?.user_profile_pic_url}
                style={{ borderRadius: "30px", marginRight: "10px" }}
              ></img>
            </div>
            <div>
              <div
                style={{
                  color: "black",
                  fontSize: "18px",
                }}
              >
                {` ${userBid[1]?.user_login_name}`}
              </div>
              <div
                style={{
                  color: "gray",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                {`  ${userBid[1]?.bid_dt}`}
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: "12px",
                  marginTop: "-5px",
                }}
              >{`RM ${
                global_Top2_Bid_Amount != 0
                  ? global_Top2_Bid_Amount
                  : "loading..."
              }`}</div>
            </div>
          </div>
        </div>
        <div style={{ backgroundColor: "lightgreen", marginBottom: "2px" }}>
          # Top 3 Bid Amount
          <div style={{ display: "flex" }}>
            <div>
              <img
                src={userBid[2]?.user_profile_pic_url}
                style={{ borderRadius: "30px", marginRight: "10px" }}
              ></img>
            </div>
            <div>
              <div
                style={{
                  color: "black",
                  fontSize: "18px",
                }}
              >
                {` ${userBid[2]?.user_login_name}`}
              </div>
              <div
                style={{
                  color: "gray",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                {`  ${userBid[2]?.bid_dt}`}
              </div>
              <div
                style={{
                  color: "black",
                  fontSize: "12px",
                }}
              >{`RM ${
                global_Top3_Bid_Amount != 0
                  ? global_Top3_Bid_Amount
                  : "loading..."
              }`}</div>
            </div>
          </div>
        </div> */}
        <hr />
        {registeredUserStatus ? (
          <>
            {/* <div>
              # My Bid Highest Amount
              <div
                style={{
                  color: "gold",
                  backgroundColor: "black",
                  fontSize: "14px",
                  width: "95%",
                }}
              >{`RM ${
                my_Highest_Bid_Amount != 0
                  ? my_Highest_Bid_Amount
                  : "loading..."
              }`}</div>
            </div>
            <br />
            <div>MY ID : {db_u_id}</div>
            <div>Item No : {bid_ItemNo}</div>
            <div>Item Set : {bid_ItemSet}</div>
            <div>Min Bid Amount : {bid_minAmount_eachTime}</div> */}

            {true ? (
              <>
                <div style={{ display: "flex" }}>
                  <TextField
                    fullWidth
                    // autoFocus
                    type="text"
                    value={my_Bid_Amount}
                    label="My Bid Amount"
                    placeholder="My Bid Amount"
                    helperText={
                      <div style={{ color: "red" }}>
                        must over RM
                        {parseInt(global_Top1_Bid_Amount) +
                          parseInt(bid_minAmount_eachTime)}
                      </div>
                    }
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: 20,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    inputProps={{
                      shrink: true,
                      style: {
                        fontSize: 30,
                        color: "#ffffff",
                        // backgroundColor: "black",
                      },
                    }}
                    onChange={(event) => {
                      setMy_Bid_Amount(
                        event.target.value.replace(/[^0-9]/g, "")
                      );
                    }}
                  />

                  <Button
                    onClick={() => {
                      if (
                        my_Bid_Amount <
                        parseInt(global_Top1_Bid_Amount) +
                          parseInt(bid_minAmount_eachTime)
                      ) {
                        alert(
                          `#E2. My bid amount ${my_Bid_Amount} Must be Greater then RM ${
                            parseInt(global_Top1_Bid_Amount) +
                            parseInt(bid_minAmount_eachTime)
                          } each bid and Each Time Must be Greater or Equal to RM ${bid_minAmount_eachTime} each bid`
                        );
                      } else {
                        if (
                          window.confirm(
                            `Confirm add RM ${my_Bid_Amount} to this bid?`
                          )
                        ) {
                          setDB_userBid(
                            db_u_id,
                            my_Bid_Amount,
                            bid_ItemNo,
                            bid_ItemSet
                          );
                        }
                      }
                    }}
                    style={{
                      color: "#ffffff",
                      textShadow: "0px 0px 20px gold",
                      fontSize: "30px",
                    }}
                  >
                    BID!
                  </Button>
                </div>
                <Button
                  onClick={() => {
                    if (
                      window.confirm(
                        `Confirm add RM ${50} to this bid? (my highest amount will become RM${
                          parseInt(global_Top1_Bid_Amount) +
                          parseInt(bid_minAmount_eachTime)
                        })`
                      )
                    ) {
                      setDB_userBid(
                        db_u_id,
                        parseInt(global_Top1_Bid_Amount) +
                          parseInt(bid_minAmount_eachTime),
                        bid_ItemNo,
                        bid_ItemSet
                      );
                    }
                  }}
                  style={{
                    color: "#ffffff",
                    textShadow: "0px 0px 20px gold",
                    fontSize: "30px",
                  }}
                >
                  +RM {bid_minAmount_eachTime}
                </Button>
              </>
            ) : (
              "Loading..."
            )}
          </>
        ) : (
          <div style={{ color: "blue", fontSize: "30px" }}>
            Please Proceed to Registered
            <Button
              onClick={() => {
                window.location.href = "/#/register";
              }}
            >
              Register
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
