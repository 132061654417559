import React, { useEffect, useState, useRef } from "react";

import axios from "axios";

import { Button } from "@mui/material";

import ReplayIcon from "@mui/icons-material/Replay";
import MaterialTable from "material-table";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

export default function ThisApp() {
  //  -------------------------- DB
  const [eventUser_All, setEventUser_All] = useState([]);

  const getData_EventUser_All = () => {
    axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user_all`)
      .then((date) => {
        setEventUser_All(date.data);
        console.log("getData_EventUser_All", date.data);
      });
  };

  //  -------------------------- Table column

  const [state, setState] = useState({
    columns1: [
      {
        title: "User ID",
        field: "id",
        width: "1px",
        cellStyle: {
          color: "blue",
        },
        headerStyle: {
          color: "blue",
        },
      },
      {
        title: "Role",
        field: "user_role",
        width: "1px",
      },
      {
        title: "User Name",
        field: "user_login_name",
        width: "1px",
        cellStyle: {
          backgroundColor: "#2bc3ff",
          color: "#000",
        },
        headerStyle: {
          backgroundColor: "#2bc3ff",
          color: "#000",
        },
        render: (rowData) => (
          <>
            <div style={{ display: "flex" }}>
              <img
                src={rowData.user_profile_pic_url}
                style={{ borderRadius: "30px" }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "10px",
                }}
              >
                <b> {rowData.user_login_name} </b>
                <div> {rowData.user_login_email} </div>
              </div>
            </div>
          </>
        ),
      },
      {
        title: (
          <>
            <img
              src="https://store.cloudflare.steamstatic.com/public/shared/images/header/logo_steam.svg?t=962016"
              style={{ width: "100px" }}
            />
          </>
        ),
        field: "user_steamname",
        width: "1px",
        cellStyle: {
          backgroundColor: "#5C7E10",
          color: "#ffffff",
        },
        headerStyle: {
          backgroundColor: "#5C7E10",
          color: "#ffffff",
        },
        render: (rowData) => (
          <div>
            {rowData.user_steamname.includes("dummy")
              ? "NO"
              : rowData.user_steamname}
          </div>
        ),
      },
      {
        title: "Email",
        field: "user_login_email",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          //color: "white",
        },
      },
      {
        title: "Contact",
        field: "user_contactno",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          //color: "white",
        },
      },
      {
        title: "Delivery Address",
        field: "user_deliveryadd",
        width: "1px",
        headerStyle: {
          //color: "white", minWidth: "200px"
        },
        cellStyle: {
          //color: "white"
        },
        render: (rowData) => (
          <>{`${rowData.user_deliveryadd},${rowData.user_postcode}, ${rowData.user_city} `}</>
        ),
      },
      {
        title: "CP1",
        field: "user_event1_checkpoint",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          //color: "white",
        },
      },
      {
        title: "CP2",
        field: "user_event2_checkpoint",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          //color: "white",
        },
      },
      {
        title: "CP3",
        field: "user_event3_checkpoint",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          //color: "white",
        },
      },
      {
        title: "Register Date",
        field: "user_register_dt",
        width: "1px",
        headerStyle: {
          //color: "white",
        },
        cellStyle: {
          fontSize: "10px",
          //color: "white",
        },
      },
    ],
  });

  useEffect(() => {
    getData_EventUser_All();
  }, []);

  return (
    <>
      {" "}
      <>
        <br />
        <MaterialTable
          style={{
            color: "black",
            background: "rgba(255, 255, 255, .9)",
            fontSize: "12px",
            boxShadow: "0 8px 32px 0 rgba( 0, 0, 0, 0.4 )",
            backdropFilter: "blur( 11.0px )",
            "&::webkit-backdrop-filter": {
              webkitBackdropFilter: "blur( 18.0px )",
            },
          }}
          title={
            <div style={{ color: "#2bc3ff", fontSize: "30px" }}>
              {`User Info ( Total : ${
                eventUser_All != undefined ? eventUser_All.length : "Loading..."
              })`}
              <Button
                style={{ fontSize: "20px", color: "black" }}
                onClick={() => getData_EventUser_All()}
              >
                <ReplayIcon />
                Reload Data
              </Button>
            </div>
          }
          columns={state.columns1}
          data={eventUser_All}
          options={{
            headerStyle: {
              background: "rgba(255, 255, 255, .2)",
              border: "none",
            },
            exportButton: true,
            //                 rowStyle: (rowData) => ({
            //                   backgroundColor:
            //                     rowData.Item_stock_balance < 0
            //                       ? "#ff0000"
            //                       : rowData.Item_stock_balance === 0
            //                       ? "#1a0000"
            //                       : "",
            //                   color: rowData.Item_stock_balance < 10 ? "#ffcc00" : "#ffffff",
            //                   // selectedRow === rowData.tableData.id ? "#006644" : "#262626",
            //                   fontSize: 12,
            //                 }),
            filtering: true,
            //                 grouping: true,
            //                 exportAllData: true,
            pageSize: 10,
            pageSizeOptions: [20, 50, 100],
            paginationType: "stepped",
            // actionsColumnIndex: -1
            // fixedColumns: {
            //     left: 1,
            // }
          }}
          onRowClick={(evt, selectedRow) => {
            // alert(selectedRow.id);
          }}
          actions={
            [
              // {
              //   icon: "save",
              //   tooltip: "Save User",
              //   onClick: (event, rowData) => {
              //     handleClickOpen_1_1();
              //     // getDB_patroninfo_byid(rowData.id);
              //     // alert(rowData.id);
              //   },
              //   onClick2: (event, rowData) => {
              //     handleClickOpen_1_2();
              //     // getDB_patroninfo_byid(rowData.id);
              //     // alert(rowData.id);
              //   },
              // },
            ]
          }
          components={
            {
              // Action: (props) => (
              // <>
              //   <Button
              //     // classes={{ root: classes.button }}
              //     onClick={(event) => props.action.onClick(event, props.data)}
              //   >
              //     EDIT
              //   </Button>
              //   <Button
              //     // classes={{ root: classes.button }}
              //     onClick={(event) => props.action.onClick2(event, props.data)}
              //   >
              //     <div style={{ color: "#2bc3ff" }}>MANAGE</div>
              //   </Button>
              // </>
              // ),
            }
          }
        />
      </>
    </>
  );
}
