import react, { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import axios from "axios";
import ScrollToBottom from "react-scroll-to-bottom";
import MaterialTable from "material-table";

// import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Page_ScoreBoard from "./Page_ScoreBoard";
import Page_ChatRoom_Lobby from "./Page_ChatRoom_Lobby";
import Page_TimerCountDown from "./Page_TimerCountDown";
import Page_Event from "./Page_Event";
import Page_Redeem from "./Page_Redeem";
import Page_RulesAndRegulation from "./Page_RulesAndRegulation";
import Page_Register from "./Page_Register";
import Page_Event_Now from "./Page_Event_Now";
import Page_Event_Next from "./Page_Event_Next";
import Page_LobbyCode from "./Page_LobbyCode";
import Page_UserProfile from "./Page_UserProfile";
import Page_Login from "./Page_Login";

import COM_Carousel from "../Component/COM_Carousel";
import COM_Carousel2 from "../Component/COM_Carousel2";

import { TabContext, TabList, TabPanel } from "@mui/lab";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
  REACT_APP_ECLIPSE_API_IOSOCKET,
} = process.env;

// const socket = io.connect("http://localhost:8001");
const socket = io.connect(REACT_APP_ECLIPSE_API_IOSOCKET.toString());

export default function X() {
  // ------------------------------------------------- DB

  // get user info by uid
  const [firebaseAPI_DB_users, setFirebaseAPI_DB_users] = useState([]);
  const getFirebaseAPI_DB_users_List = async (user_login_uid) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setFirebaseAPI_DB_users(res.data[0]);
        // console.log("getFirebaseAPI_DB_users_List", res.data[0]);
        if (res.data[0] != undefined) {
          // that is registered user
          setRegisteredUserStatus(true);
          // get the user data
          setDb_u_id(res.data[0].id);
          setDeliveryAdd(res.data[0].user_deliveryadd);
          setPostCode(res.data[0].user_postcode);
          setCity(res.data[0].user_city);
          setContactNo(res.data[0].user_contactno);
          setSteamName(res.data[0].user_steamname);
          setCb_User_event1_checkpoint(
            res.data[0]?.user_event1_checkpoint == "true" ? true : false
          );
          // console.log("db chkp1 = ", res.data[0]?.user_event1_checkpoint);
        } else {
          setRegisteredUserStatus(false);
        }
      });
  };

  // get user info by uid
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = async (user_login_uid) => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        setEventSetting(res.data[0]);
        // console.log("eventSetting", res.data[0]);
        setYt_LiveLink(res.data[0].yt_live_url);
      });
  };

  // get user score
  const [userScore, setUserScore] = useState([]);
  const getDB_userScore = async (user_login_uid) => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_score`)
      .then((res) => {
        // console.log("userScore", res.data[1]);
        setUserScore(res.data[1]);
      });
  };
  //  -------------------------- Table column

  const [state, setState] = useState({
    columns_userScore: [
      // {
      //   title: "No",
      //   field: "num",
      //   width: "1px",
      // },
      {
        title: "Steam Name",
        field: "user_steamname",
        width: "1px",
        render: (rowData) => (
          <>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "10px" }}>#{rowData.num}</div>
              <img
                src={rowData.user_profile_pic_url}
                style={{ borderRadius: "30px", height: "30px" }}
              />

              <div> {rowData.user_steamname}</div>
            </div>
          </>
        ),
      },
      {
        title: "Score",
        field: "total_score",
        width: "1px",
      },
    ],
  });

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  const singInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  const signInWithFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(authentication, provider)
      .then((re) => {
        setFirebase_userInfo(re);
        // console.log("re", re);
      })
      .catch((err) => {
        // console.log("re-err", err);
      });
  };

  // -------------------------------------- Global Variable

  const [deliveryAdd, setDeliveryAdd] = useState();
  const [postCode, setPostCode] = useState();
  const [city, setCity] = useState();
  const [contactNo, setContactNo] = useState();

  const [db_u_id, setDb_u_id] = useState();
  const [steamName, setSteamName] = useState(
    "dummyAcc-" + Math.random() * 100000000000000000
  );

  const [yt_LiveLink, setYt_LiveLink] = useState("");

  const [cb_User_event1_checkpoint, setCb_User_event1_checkpoint] =
    useState(false);
  const hC_cb_User_event1_checkpoint = (event) => {
    setCb_User_event1_checkpoint(event.target.checked);
    setSteamName("");
  };

  const [registeredUserStatus, setRegisteredUserStatus] = useState(false);

  //---------------------------------- tabs
  const [navTab_Value, setNavTab_Value] = useState(1);

  useEffect(() => {
    socket.on("received_message", (data) => {
      // console.log("#received_message", data);
      setYt_LiveLink(data.yt_live_url);
      setTimeout(() => {
        setPlay(true);
        console.log("p");
      }, 1000);
    });
  }, [socket]);

  useEffect(() => {
    socket.emit("join_room", "eventBroadcast");
    getDB_eventSetting();
    getDB_userScore();
    document.documentElement.scrollTop = 0;
  }, []);

  // -------------------------------------------------  firebase - 3/3

  useEffect(() => {
    // console.log("#useEffect 1", firebase_userInfo?.displayName);
    // console.log("#useEffect2. ", firebase_userInfo);
    if (firebase_userInfo?.uid) {
      // console.log("a1", firebase_userInfo?.uid);
      // console.log("a1", firebase_userInfo?.email);
      getFirebaseAPI_DB_users_List(firebase_userInfo?.uid);

      // nevigate to main page
      // window.location.href = "#/main";
    }
  }, [firebase_userInfo]);

  //---- test yt

  const myRefname = useRef(null);

  const [play, setPlay] = useState(false);
  const url = play
    ? `https://www.youtube.com/embed/tu-bgIg-Luo?autoplay=1`
    : `https://www.youtube.com/embed/tu-bgIg-Luo`;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <div className="NavBar_bg">
            {/* B1 : Nav */}
            <div
              className="Text_EclipsePlay"
              onClick={() => {
                setNavTab_Value(0);
              }}
            >
              Eclipse Play
            </div>
            <div
              className="NavBar_child"
              onClick={() => {
                setNavTab_Value(1);
              }}
            >
              Watch
            </div>
            <div
              className="NavBar_child"
              onClick={() => {
                setNavTab_Value(2);
              }}
            >
              Event
            </div>

            <div
              style={{
                visibility: registeredUserStatus ? "visible" : "hidden",
              }}
              className="NavBar_child"
              onClick={() => {
                setNavTab_Value(3);
              }}
            >
              Redeem
            </div>
            <div
              className="NavBar_child"
              onClick={() => {
                setNavTab_Value(4);
              }}
            >
              RULES
            </div>
            <div
              style={{
                visibility: registeredUserStatus ? "visible" : "hidden",
              }}
              className="NavBar_child"
              onClick={() => {
                setNavTab_Value(5);
              }}
            >
              EDIT PROFILE
            </div>
            <div className="">
              {firebase_userInfo && registeredUserStatus ? (
                <div className="div_alignToRight  ">
                  <Page_UserProfile
                    registeredUserStatus={registeredUserStatus}
                    setRegisteredUserStatus={setRegisteredUserStatus}
                  />
                </div>
              ) : (
                <div
                  className="NavBar_child div_alignToRight"
                  onClick={() => {
                    setNavTab_Value(6);
                  }}
                >
                  REGISTER NOW!
                </div>
              )}
            </div>
          </div>
          {/* // -------------- nav response */}
          {navTab_Value == 1 ? (
            <div className="NavBar_Response div_maximumWidth">
              <Grid item xs={12}>
                <div style={{ backgroundColor: "blue" }}>
                  B2 : Welcome
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <div style={{ backgroundColor: "white" }}>
                        B3 : Event Now
                        <Page_Event_Now db_u_id={db_u_id} />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        class="video-container"
                        style={{ backgroundColor: "purple" }}
                      >
                        B4 : Live video
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <div
                              class="video-container"
                              style={{ backgroundColor: "" }}
                            >
                              B4 : Live video
                              <iframe
                                // width={560 * 0.4} //560 56
                                // height={315 * 0.4} //315  31

                                src={`${yt_LiveLink}${
                                  play ? "?autoplay=1" : ""
                                }`}
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen=""
                              ></iframe>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid container xs={12} spacing={1}>
                            <Grid item xs={4}>
                              <div style={{ backgroundColor: "pink" }}>
                                L1
                                <div
                                  class="video-container"
                                  style={{ backgroundColor: "lime" }}
                                >
                                  C2 : YT logo_Eclipse
                                  <iframe
                                    // width={560 * 0.4} //560 56
                                    // height={315 * 0.4} //315  31

                                    src="https://www.youtube.com/embed/videoseries?list=PLSOdobpV2V0YNtckWKzL97NOhg72WO0I9&autoplay=1&mute=1"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen=""
                                  ></iframe>
                                </div>
                              </div>
                            </Grid>
                            <Grid item xs={4}>
                              <div
                                style={{
                                  backgroundColor: "",
                                  // height: "300px",
                                }}
                              >
                                <br />

                                <Page_LobbyCode />
                              </div>
                              <br />
                              {/* <COM_Carousel /> */}
                            </Grid>
                            <Grid item xs={4}>
                              <br />
                              <div style={{ backgroundColor: "" }}>
                                <Page_Event_Next />
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div style={{ marginTop: "0px" }}></div>

                      <Grid item xs={12}>
                        <div style={{ backgroundColor: "green" }}>
                          D1 : Scoreboard
                          <Page_ScoreBoard parent_game_num="1" />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <div
                          style={
                            {
                              //  backgroundColor: "lime"
                            }
                          }
                        >
                          D2 : Chat
                          {!registeredUserStatus ? (
                            <Page_Register />
                          ) : (
                            <div>
                              <Page_ChatRoom_Lobby />
                            </div>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                C1
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <div style={{ backgroundColor: "" }}>Empty1</div>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div />
          )}

          {navTab_Value == 2 ? (
            <div className="NavBar_Response div_maximumWidth">
              <Page_Event />
            </div>
          ) : (
            <div />
          )}

          {navTab_Value == 3 ? (
            <div className="NavBar_Response div_maximumWidth">
              <Page_Redeem Parent_u_steamname={steamName} />
            </div>
          ) : (
            <div />
          )}

          {navTab_Value == 4 ? (
            <div className="NavBar_Response div_maximumWidth">
              <Page_RulesAndRegulation />
            </div>
          ) : (
            <div />
          )}

          {navTab_Value == 5 ? (
            <div className="NavBar_Response div_maximumWidth">
              <Page_Register />
            </div>
          ) : (
            <div />
          )}

          {navTab_Value == 6 ? (
            <div className="NavBar_Response div_maximumWidth">
              Login pageesss
              <Page_Register />
            </div>
          ) : (
            <div />
          )}
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <div style={{ fontSize: "40px", color: "gold", textAlign: "center" }}>
        Like & Share
      </div>
      <br />
      <Grid
        container
        spacing={3}
        style={{ color: "gold", padding: "10px 10px 10px 10px " }}
      >
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Sades.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/sades.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Serverdna.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/serverdna"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Maxsun.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/MaxsunMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_TitanArmy.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/TitanArmyMy"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Madtech.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/madtech.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_InWin.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/inwin.my"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Eclipse.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/EclipseGroupMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
        <Grid container xs={4}>
          <Grid item xs={4}>
            <img
              src="https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/logo_Alitrade.png"
              height="30px"
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ padding: "10px 0px 0px 10px" }}>
              <div
                class="fb-like"
                data-href="https://www.facebook.com/AlitradeMY"
                data-width="100"
                data-layout="standard"
                data-action="like"
                data-size="large"
                data-colorscheme="dark"
              ></div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
