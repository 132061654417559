import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import moment from "moment";

import axios from "axios";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_ChatRoom_Room({
  socket,
  username,
  photoURL,
  room,
  role,
  steamName,
  uid,
  DB_uid,
  totalOnlineUser,
}) {
  // ------------------------------------------------- DB

  // get user info by uid
  const [previousChatLog, setPreviousChatLog] = useState([]);
  const getPreviousChatLog = async (limit) => {
    const x = await axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__chat_log_all_limitby?limit=${limit}`
      )
      .then((res) => {
        // console.log("res qqq", res.data);

        setMessageList((list) => [...list, ...res.data]);
      });
  };

  // ------------------------------------------------- Props

  const [currentMessage, setCurrentMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const sendMessage = async () => {
    // console.log("socket :", socket);
    // console.log("username :", username);
    // console.log("photoURL :", photoURL);
    // console.log("room :", room);
    // console.log("role :", role);
    // console.log("steamName :", steamName);
    // console.log("DB_uid :", DB_uid);
    if (currentMessage !== "") {
      const messageData = {
        room: room,
        author: username,
        photoURL: photoURL,
        role: role,
        steamName: steamName,
        message: currentMessage,
        time: moment().format("HH:mm:ss"),
        uid: uid,
        DB_uid: DB_uid,
      };

      await socket.emit("send_message", messageData);

      setMessageList((list) => [...list, messageData]);
      setCurrentMessage("");
    }
  };

  useEffect(() => {
    // console.log("username", username);
    getPreviousChatLog(100);
  }, []);

  useEffect(() => {
    socket.on("received_message", (data) => {
      // console.log("#received_message", data);
      setMessageList((list) => [...list, data]);
    });
  }, [socket]);

  return (
    <div className="chat-window">
      <div className="chat-header">
        <div style={{ color: "#fff", display: "flex" }}>
          <div>{`Live Chat`} </div>
          <div style={{ color: "#8a8a8a" }}> {`- (${totalOnlineUser})`}</div>
        </div>
      </div>
      <div className="chat-body">
        <ScrollToBottom className="message-container">
          {messageList.map((messageContent) => {
            return (
              <>
                <div
                  className="message"
                  style={{
                    background:
                      messageContent.role === "admin"
                        ? "#eddcfc"
                        : messageContent.role === "gm"
                        ? "#fff3db"
                        : "",
                  }}
                  // id={username === messageContent.author ? "you" : "other"}
                  id={uid === messageContent.uid ? "you" : "other"}
                >
                  <div style={{ display: "flex" }}>
                    <div>
                      <img
                        style={{
                          borderRadius: "30px",
                          height: "30px",
                        }}
                        src={messageContent.photoURL}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "10px",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ color: "black" }}>
                        <b>{`${messageContent.author}`}</b>
                      </div>
                      <div
                        style={{
                          color: "gray",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        <>{`${messageContent.steamName}`}</>
                      </div>
                      {/* <div
                        style={{
                          color: "blue",
                          fontSize: "10px",
                          marginTop: "-5px",
                        }}
                      >
                        <>{`${messageContent.uid}`}</>
                      </div> */}

                      {messageContent.role === "admin" ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "10px",
                            marginTop: "-5px",
                          }}
                        >
                          🐝<b>{`${messageContent.role}`}</b>
                        </div>
                      ) : messageContent.role === "gm" ? (
                        <div
                          style={{
                            color: "orange",
                            // background: "black",
                            fontSize: "10px",
                            marginTop: "-5px",
                            // borderRadius: "30px",
                          }}
                        >
                          🎅 <b>{`${messageContent.role}`}</b>
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "blue",
                            fontSize: "10px",
                            marginTop: "-5px",
                          }}
                        >
                          🎄 <b>{`${messageContent.role}`}</b>
                        </div>
                      )}
                      <div
                        style={{
                          color: "#e0dede",
                          fontSize: "9px",
                          marginTop: "-2px",
                        }}
                      >{`${messageContent.time}`}</div>
                    </div>
                    <div className="message-content">
                      <div>{messageContent.message}</div>
                    </div>
                  </div>
                </div>

                <div
                  className="message"
                  // id={username === messageContent.author ? "you" : "other"}
                  id={uid === messageContent.uid ? "you" : "other"}
                >
                  <div className="message-meta"></div>
                </div>
              </>
            );
          })}
        </ScrollToBottom>
      </div>
      <div style={{ display: "flex" }}>
        <TextField
          fullWidth
          autoFocus
          type="text"
          value={currentMessage}
          placeholder="I want to say..."
          InputLabelProps={{
            shrink: true,
            style: {
              fontSize: 20,
              color: "#ffffff",
              // backgroundColor: "black",
            },
          }}
          inputProps={{
            shrink: true,
            style: {
              fontSize: 30,
              color: "#73ff00",
              // backgroundColor: "black",
            },
          }}
          onChange={(event) => {
            setCurrentMessage(event.target.value);
          }}
          onKeyPress={(event) => {
            event.key === "Enter" && sendMessage();
          }}
        />
        <Button
          onClick={sendMessage}
          style={{ color: "#73ff00", fontSize: "30px" }}
        >
          &#9658;
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => setCurrentMessage(currentMessage + "👍")}
          style={{ color: "#73ff00", fontSize: "20px", padding: "0px" }}
        >
          👍
        </Button>
        <Button
          onClick={() => setCurrentMessage(currentMessage + "😍")}
          style={{ color: "#73ff00", fontSize: "20px", padding: "0px" }}
        >
          😍
        </Button>
        <Button
          onClick={() => setCurrentMessage(currentMessage + "😱")}
          style={{ color: "#73ff00", fontSize: "20px", padding: "0px" }}
        >
          😱
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          onClick={() => setCurrentMessage(currentMessage + "😡")}
          style={{ color: "#73ff00", fontSize: "20px", padding: "0px" }}
        >
          😡
        </Button>
        <Button
          onClick={() => setCurrentMessage(currentMessage + "🥺")}
          style={{ color: "#73ff00", fontSize: "20px", padding: "0px" }}
        >
          🥺
        </Button>
      </div>

      <Button
        onClick={() =>
          window.open(
            "http://www.iemoji.com/#?category=smileys-people&version=36&theme=appl&skintone=default"
          )
        }
        style={{ color: "#5c5c5c", fontSize: "10px", padding: "0px" }}
      >
        more...
      </Button>
      <Button
        onClick={() =>
          window.open("https://unicode.org/emoji/charts/full-emoji-list.html")
        }
        style={{ color: "#5c5c5c", fontSize: "10px", padding: "0px" }}
      >
        more...
      </Button>
    </div>
  );
}
