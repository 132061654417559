import react, { useEffect, useState } from "react";
import moment from "moment";

import axios from "axios";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";
import MaterialTable from "material-table";

import Page_Bidding from "./Page_Bidding";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({ db_u_id }) {
  // ------------------------------------------------- DB

  // get event setting
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        // console.log("getDB_eventSetting", res.data[0]);
        setEventSetting(res.data[0]);
      });
  };

  // ------------------------------------- time var

  const [sec, setSec] = useState();
  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    setSec(d.seconds());
  };

  //  -------------------------- Global Variable
  const [current_TotalBid, setCurrent_TotalBid] = useState("");
  const [my_TotalBid, setMy_TotalBid] = useState("");

  const [my_Bid, setMy_Bid] = useState("");

  useEffect(() => {
    //     getDB_userBid();
    getDB_eventSetting();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      DateTimeCounter();
      getDB_eventSetting();
    }, 1000 * 10 * 1); // 5min update 1
  }, [sec]);

  return (
    <>
      {eventSetting.length == 0 ? (
        <div style={{ color: "gold", fontSize: "20px" }}> Loading... </div>
      ) : (
        ""
      )}

      {/* //------------ now0 */}
      {eventSetting.now_trigger == 0 ? (
        <>
          <br />
          <img
            src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/now0.png`}
            width="100%"
          />
        </>
      ) : (
        ""
      )}

      {/* //------------ now1 */}
      {eventSetting.now_trigger == 1 ? (
        <Page_Bidding
          db_u_id={db_u_id}
          bid_ItemNo={eventSetting.now_trigger} //"2"
          bid_ItemSet={eventSetting.bidding_item_set}
          bid_minAmount_eachTime="50"
          timerCounter_Until={eventSetting.countdown_ultil_dt}
          //"2021-12-03 20:21:49"
          //"03/12/2021 19:33:00"
        />
      ) : (
        ""
      )}
      {/* //------------ now2 */}
      {eventSetting.now_trigger == 2 ? (
        <Page_Bidding
          db_u_id={db_u_id}
          bid_ItemNo={eventSetting.now_trigger} //"2"
          bid_ItemSet={eventSetting.bidding_item_set}
          bid_minAmount_eachTime="5"
          timerCounter_Until={eventSetting.countdown_ultil_dt}
          //"03/12/2021 19:33:00"
        />
      ) : (
        ""
      )}
      {/* //------------ now3 */}
      {eventSetting.now_trigger == 3 ? (
        <Page_Bidding
          db_u_id={db_u_id}
          bid_ItemNo={eventSetting.now_trigger} //"2"
          bid_ItemSet={eventSetting.bidding_item_set}
          bid_minAmount_eachTime="5"
          timerCounter_Until={eventSetting.countdown_ultil_dt}
          //"03/12/2021 19:33:00"
        />
      ) : (
        ""
      )}
      {/* //------------ now4 */}
      {eventSetting.now_trigger == 4 ? (
        <Page_Bidding
          db_u_id={db_u_id}
          bid_ItemNo={eventSetting.now_trigger} //"2"
          bid_ItemSet={eventSetting.bidding_item_set}
          bid_minAmount_eachTime="5"
          timerCounter_Until={eventSetting.countdown_ultil_dt}
          //"03/12/2021 19:33:00"
        />
      ) : (
        ""
      )}
      {/* //------------ now5 */}
      {eventSetting.now_trigger == 5 ? (
        <>
          <br />
          <img
            src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/now5.png`}
            width="100%"
          />
        </>
      ) : (
        ""
      )}
      {/* //------------ now6 */}
      {eventSetting.now_trigger == 6 ? (
        <>
          <br />
          <img
            src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/now6.png`}
            width="100%"
          />
        </>
      ) : (
        ""
      )}
    </>
  );
}
