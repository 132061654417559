import React, { Component, useState, useReducer, useEffect } from "react";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";

export default function COM_ItemCart({
  p_data,
  p_data2,
  addDB_item_StockBalance_P1,
  firebase_userInfo,
}) {
  const [item, setItem] = useState();
  const [carts, dispatch] = useReducer(reducer, []);
  function reducer(_carts, _action) {
    if (_action.type === "add-todo") {
      return [..._carts, newTodo(_action.payload.item)];
    } else if (_action.type === "delete-todo") {
      return _carts.filter((todo) => todo.id !== _action.payload.id);
    } else if (_action.type === "clear-todo") {
      return [];
    } else {
      return _carts;
    }
  }

  function newTodo(_item) {
    return { id: Date.now(), item: _item, complete: false };
  }

  // add item
  const addItem = (_item) => {
    dispatch({ type: "add-todo", payload: { item: _item } });
    setItem("");
  };

  // dialog
  const handleClickOpen = () => {
    setDialog_Open(true);
  };

  const handleClose = () => {
    setDialog_Open(false);
    console.log("carts", carts);
  };
  const [dialog_Open, setDialog_Open] = React.useState(false);

  return (
    <>
      <div
        className="cart_btn"
        onClick={() => {
          // addItem({ item_id: 12, item_name: "maxsun", item_qty: 1 });
          setDialog_Open(true);
        }}
      >
        <ShoppingCartIcon style={{ marginRight: "5px" }} />
        {`My Cart (${carts.length})`}
      </div>

      <div className="cart_flexbox">
        {p_data.map((x) => {
          return (
            <div
              className="cart_flexbox_child"
              onClick={() => {
                console.log(
                  "p_data2",
                  p_data2.find((y) => y.item_id == x.id)
                );

                if (
                  p_data2.find((y) => y.item_id == x.id)?.item_balance != 0 &&
                  p_data2.find((y) => y.item_id == x.id) != undefined
                ) {
                  addItem({
                    item_id: x.id,
                    item_brand: x.item_brand,
                    item_name: x.item_name,
                    item_qty: 1,
                    item,
                  });
                } else {
                  alert("OUT OF STOCK");
                }
              }}
            >
              <div className="cart_item_top">
                <div className="div_arrange_Horizontal ">
                  <div> {x.item_require_coin}</div>
                  <div className="Coin_ico_small">$</div>
                </div>
              </div>
              <img
                className="cart_flexbox_child_img"
                src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/item/${x.id}.png`}
              />
              <div className="cart_item_bottom">
                <div> {`[${x.item_brand}] ${x.item_name}`}</div>
              </div>
              <div className="div_arrange_Horizontal ">
                <div className="cart_item_priceworth">{` worth rm ${parseFloat(
                  x.item_price
                ).toFixed(2)}`}</div>
                <div
                  className="cart_item_itemqtybal"
                  style={{ textAlign: "right" }}
                >
                  {`${
                    p_data2?.filter((y) => y.item_id == x.id)[0]?.item_balance
                  } left`}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <>
        <Dialog
          className="cart_dialog"
          PaperProps={{
            style: {
              background: "rgba(255, 255, 255, 1)",
              boxShadow: "0 8px 32px 0 rgba( 255, 255, 255, 0.5 )",
              backdropFilter: "blur( 11.0px )",
            },
          }}
          // scroll="body"
          // fullScreen
          fullWidth
          maxWidth="xl"
          open={dialog_Open}
          onClose={handleClose}
        >
          <DialogTitle>
            {
              <div className="div_arrange_Horizontal">
                <ShoppingCartIcon style={{ marginRight: "5px" }} />
                <div>My Cart (</div>
                <div style={{ color: "blue" }}>{carts.length}</div>
                <div> items)</div>
              </div>
            }
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {carts.map((cart) => {
                return (
                  <Cart_Item key={cart.id} carts={cart} dispatch={dispatch} />
                );
              })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div
              className="normal_btn"
              onClick={() => {
                handleClose();
                carts.map((cart) => {
                  addDB_item_StockBalance_P1(
                    cart.item.item_id,
                    firebase_userInfo.email,
                    p_data.find((y) => y.id == cart.item.item_id)
                      .item_require_coin * -1
                  );
                  dispatch({ type: "clear-todo", payload: { id: carts.id } });
                });
              }}
              autoFocus
            >
              <div className="div_arrange_Horizontal">
                <ShoppingCartCheckoutIcon />
                <div style={{ color: "blue" }}>{`(${carts.length}) `}</div>
                <div>Check Out </div>
              </div>
            </div>
            {carts.length > 0 ? (
              <div
                className="alert_btn"
                onClick={() =>
                  dispatch({ type: "clear-todo", payload: { id: carts.id } })
                }
              >
                Clear
              </div>
            ) : (
              <div />
            )}
            <Button
              style={{ padding: "30px" }}
              onClick={() => {
                handleClose();
                alert("Checked Out");
              }}
              onClick={() => {
                handleClose();
              }}
              autoFocus
            >
              CLOSE
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
}

// child component
function Cart_Item({ carts, dispatch }) {
  return (
    <div className="div_arrange_Horizontal container_simple">
      <div>{`${carts.item.item_qty} x `}</div>
      <img
        className="cart_flexbox_child_img_small"
        src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/item/${carts.item.item_id}.png`}
      />
      <div style={{ color: "black", width: "70%", textAlign: "left" }}>
        {`(item id: ${carts.item.item_id}) [${carts.item.item_brand}] ${carts.item.item_name}`}

        <Button
          style={{
            color: "red",
            position: "absolute",
            right: "0",
            marginRight: "40px",
          }}
          onClick={() =>
            dispatch({ type: "delete-todo", payload: { id: carts.id } })
          }
        >
          Delete
        </Button>
      </div>
    </div>
  );
}
