import react, { useEffect, useState } from "react";
import moment from "moment";

import axios from "axios";
import { Box, Paper, Grid, Button, TextField } from "@mui/material";
import MaterialTable from "material-table";

import Page_Bidding from "./Page_Bidding";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function X({ db_u_id }) {
  // ------------------------------------------------- DB

  // get event setting
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = async () => {
    const x = await axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        // console.log("getDB_eventSetting", res.data[0]);
        setEventSetting(res.data[0]);
      });
  };

  // ------------------------------------- time var

  const [sec, setSec] = useState();
  const DateTimeCounter = () => {
    var now = "04/12/2021 11:00:00";
    var then = "25/11/2021 00:00:00";

    var ms = moment(now, "DD/MM/YYYY HH:mm:ss").diff(
      moment(moment(), "DD/MM/YYYY HH:mm:ss")
    );
    var d = moment.duration(ms);

    setSec(d.seconds());
  };

  //  -------------------------- Global Variable
  const [current_TotalBid, setCurrent_TotalBid] = useState("");
  const [my_TotalBid, setMy_TotalBid] = useState("");

  const [my_Bid, setMy_Bid] = useState("");

  useEffect(() => {
    //     getDB_userBid();
    getDB_eventSetting();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      DateTimeCounter();
      getDB_eventSetting();
    }, 1000 * 10 * 1); // 5min update 1
  }, [sec]);

  return (
    <>
      {/* //------------ next1 */}
      {eventSetting.next_trigger == 1 ? (
        <img
          src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/next1.png`}
          width="100%"
        />
      ) : (
        ""
      )}
      {/* //------------ next2 */}
      {eventSetting.next_trigger == 2 ? (
        <img
          src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/next2.png`}
          width="100%"
        />
      ) : (
        ""
      )}
      {/* //------------ next3 */}
      {eventSetting.next_trigger == 3 ? (
        <img
          src={`https://steamserver2.freemyip.com/uploads/EclipseMy_Event/logo/next3.png`}
          width="100%"
        />
      ) : (
        ""
      )}
    </>
  );
}
