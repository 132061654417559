import React, { useEffect, useState } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import io from "socket.io-client";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

import COM_DateTimePicker from "../Component/COM_DateTimePicker";

import moment from "moment";
import axios from "axios";

import Page_UserInfo from "./Page_UserInfo";

import {
  Box,
  Paper,
  Grid,
  Button,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
} from "@mui/material";
import COM_AutoComplte from "../Component/COM_AutoComplte";
import COM_AutoComplte_Test from "../Component/COM_AutoComplte_Test";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
  REACT_APP_ECLIPSE_API_IOSOCKET,
} = process.env;

// const socket = io.connect("http://localhost:8001");
const socket = io.connect(REACT_APP_ECLIPSE_API_IOSOCKET.toString());

export default function X() {
  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  useEffect(() => {
    console.log("firebase_userInfo", firebase_userInfo);
  }, [firebase_userInfo]);

  // ------------------------------------------------- DB

  // get eventSetting
  const [eventSetting, setEventSetting] = useState([]);
  const getDB_eventSetting = () => {
    axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_setting`)
      .then((res) => {
        setEventSetting(res.data[0]);
        console.log("eventSetting", res.data[0]);
        setYt_live_url(res.data[0].yt_live_url);
        setNow_trigger(res.data[0].now_trigger);
        setNext_trigger(res.data[0].next_trigger);
        // setBidding_trigger(res.data[0].bidding_trigger); // empty
        setBidding_item_set(res.data[0].bidding_item_set);
        setLobby_code(res.data[0].lobby_code);
        setShow_game_num(res.data[0].show_game_num);
      });
  };

  // get all registered User
  const [regUserAll, setRegUserAll] = useState([]);
  const getDB_registeredUser_All = () => {
    axios
      .get(`${REACT_APP_ECLIPSE_API}/get_eclipse_event__event_user__all`)
      .then((res) => {
        setRegUserAll(res.data);
        console.log("getDB_registeredUser_All", res.data);
      });
  };

  // set eventSetting
  const setDB_eventSetting = (column, value) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/set_eclipse_event__event_setting?column=${column}&value=${value}`
      )
      .then((res) => {
        setEventSetting(res.data[0]);
        // console.log("eventSetting", eventSetting);
      });
  };

  // add user score
  const addDB_userScore = (
    user_login_uid,
    user_login_email,
    user_login_name,
    user_profile_pic_url,
    u_score,
    u_score_desc
  ) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__user_score?user_login_uid=${user_login_uid}&user_login_email=${user_login_email}&user_login_name=${user_login_name}&user_profile_pic_url=${user_profile_pic_url}&u_score=${u_score}&u_score_desc=${u_score_desc}`
      )
      .then((res) => {
        if (res.data.affectedRows != 0) {
          alert("Insert Successful!!");
        } else {
          alert("Insert Failed!!");
        }
      })
      .catch((err) => {
        alert("Insert Failed! " + err);
      });
  };

  // add user coin
  const addDB_userCoin = (
    user_login_uid,
    user_login_email,
    user_login_name,
    user_profile_pic_url,
    u_coin,
    u_coin_desc
  ) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/add_eclipse_event__user_coin?user_login_uid=${user_login_uid}&user_login_email=${user_login_email}&user_login_name=${user_login_name}&user_profile_pic_url=${user_profile_pic_url}&u_coin=${u_coin}&u_coin_desc=${u_coin_desc}`
      )
      .then((res) => {
        if (res.data.affectedRows != 0) {
          alert("Insert Successfuly!");
        } else {
          alert("Insert Failed");
        }
      })
      .catch((err) => {
        alert("Insert Failed, " + err);
      });
  };

  // ------------------------------ global variable

  const [countdown_ultil_dt, setcountdown_ultil_dt] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [yt_live_url, setYt_live_url] = useState("");
  const [now_trigger, setNow_trigger] = useState("");
  const [next_trigger, setNext_trigger] = useState("");
  // const [bidding_trigger, setBidding_trigger] = useState("");
  const [bidding_item_set, setBidding_item_set] = useState("");
  const [lobby_code, setLobby_code] = useState("");

  const [u_uid, setU_uid] = useState("");

  const [u_steamname, setU_steamname] = useState("");
  const [game_num, setGame_num] = useState("");
  const [u_score, setU_score] = useState("");
  const [u_coin, setU_coin] = useState("");

  const [show_game_num, setShow_game_num] = useState("");

  const update_yt_LiveLink = async () => {
    await socket.emit("send_message", {
      room: "eventBroadcast",
      yt_live_url: yt_live_url,
    });
    setDB_eventSetting("yt_live_url", yt_live_url);
  };
  const update_now_trigger = async () => {
    setDB_eventSetting("now_trigger", now_trigger);
  };
  const update_next_trigger = async () => {
    setDB_eventSetting("next_trigger", next_trigger);
  };
  // const update_bidding_trigger = async () => {
  //   setDB_eventSetting("bidding_trigger", bidding_trigger);
  // };
  const update_bidding_item_set = async () => {
    setDB_eventSetting("bidding_item_set", bidding_item_set);
  };
  const update_lobby_code = async () => {
    setDB_eventSetting("lobby_code", lobby_code);
  };

  const update_countdown_ultil_dt = async () => {
    setDB_eventSetting("countdown_ultil_dt", countdown_ultil_dt);
  };

  const update_show_game_num = async () => {
    setDB_eventSetting("show_game_num", show_game_num);
  };

  //----------- COM_AutoComplte
  const [selectedValue, setSelectedValue] = useState("");
  const [selectedObj_u_coin, setSelectedObj_u_coin] = useState("");

  useEffect(() => {
    socket.emit("join_room", "eventBroadcast");
    getDB_eventSetting();
    getDB_registeredUser_All();
  }, []);

  return (
    <>
      {/* // ------------------------------------ yt_LiveLink */}
      {firebase_userInfo.email === "shawnlwy85@gmail.com" ? (
        <>
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <TextField
              fullWidth
              //autoFocus
              type="text"
              value={yt_live_url}
              label="(#1). Youtube Live Link"
              placeholder="Youtube Live Link"
              helperText="get it from Youtube -> Share -> embed -> just middle link. ie . https://www.youtube.com/embed/l0vZNI-l__A"
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setYt_live_url(event.target.value);
              }}
              onKeyPress={(event) => {
                event.key === "Enter" && update_yt_LiveLink();
              }}
            />
            <Button
              onClick={update_yt_LiveLink}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>
          {/* //----------------------------------------- now_trigger */}
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <TextField
              fullWidth
              //autoFocus
              type="text"
              value={now_trigger}
              label="(#2). Bid Event"
              placeholder="Bid Event"
              helperText={
                <>
                  <div style={{ color: "red" }}>
                    For Bedding, Keyin Datetime in (#4), then Item Set(#5) ,
                    Last Choose the Item in 1-4 (#2)
                  </div>
                  <div style={{ color: "red" }}>
                    <div>ie. 3060ti to bid </div>
                    <div>i. goto (#4) update the Ending Time </div>
                    <div>
                      ii. goto (#5) update Item Set (if this is the 2nd time for
                      3060Ti bidding key in 2 )
                    </div>
                    <div>
                      iii. goto (#2) Choose the Biding 3060 Post, Key in 1{" "}
                    </div>
                  </div>
                  <div>
                    0 = StandBy Picture , 1 = 3060ti, 2 = maxsun casing, 3 =
                    128GB SSD, 4 = 256GB SSD{" "}
                  </div>
                  <div>5 = crab game, 6 = titan army do you know game</div>
                </>
              }
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setNow_trigger(event.target.value);
              }}
              onKeyPress={(event) => {
                event.key === "Enter" && update_now_trigger();
              }}
            />
            <Button
              onClick={update_now_trigger}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>

            <br />
          </div>
          {/* //----------------------------------------- Bidding Timer Coundown */}
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <TextField
              fullWidth
              // //autoFocus
              type="text"
              value={next_trigger}
              label="(#3). Next Event"
              placeholder="Next Event"
              helperText="ie. 1 = titan army, 2 = maxsun, 3 = sades"
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setNext_trigger(event.target.value);
              }}
              onKeyPress={(event) => {
                event.key === "Enter" && update_next_trigger();
              }}
            />
            <Button
              onClick={update_next_trigger}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>
          <div
            style={{
              padding: "0px 0px 0px 0px",
              margin: "50px",
              backgroundColor: "gold",
            }}
          >
            <COM_DateTimePicker
              // value2={selectedDate1}
              onChange2={setcountdown_ultil_dt}
              orientation="landscape" //portrait /landscape
              views={["year", "month", "date"]}
              // views={["year", "month", "date"]}
            />
            <div style={{ color: "black", fontSize: "16px", margin: "20px" }}>
              {`SELECTED DATE TIME :  `}
            </div>
            <div style={{ color: "red", fontSize: "40px", margin: "20px" }}>
              <b> {` ${countdown_ultil_dt}`}</b>
            </div>
            <Button
              onClick={update_countdown_ultil_dt}
              style={{
                backgroundColor: "white",
                color: "#1e8714",
                fontSize: "30px",
                margin: "20px",
              }}
            >
              (#4). 'Ending Time' for 'Bidding' Countdown Timer UPDATE &#9658;
            </Button>
            <div style={{ color: "red", fontSize: "20px", marginLeft: "20px" }}>
              <b> CHOOSE Date Time (#4), Then Set the Bididng Event at #2</b>
            </div>
          </div>
          {/* //----------------------------------------- bidding_trigger */}
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <TextField
              fullWidth
              //autoFocus
              type="text"
              value={bidding_item_set}
              label="(#5) Item Set?"
              placeholder="Item Set?"
              helperText="if there is 2nd unit 3060ti for bid, then key in 2 "
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setBidding_item_set(event.target.value);
              }}
              onKeyPress={(event) => {
                event.key === "Enter" && update_bidding_item_set();
              }}
            />
            <Button
              onClick={update_bidding_item_set}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>

          {/* //----------------------------------------- Lobby Code */}
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <TextField
              fullWidth
              //autoFocus
              type="text"
              value={lobby_code}
              label="(#6) Lobby Code"
              placeholder="Lobby Code"
              helperText="Code for participants to join Crab Game"
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setLobby_code(event.target.value);
              }}
              onKeyPress={(event) => {
                event.key === "Enter" && update_lobby_code();
              }}
            />
            <Button
              onClick={update_lobby_code}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>
          {/* //----------------------------------------- Add User Score */}
          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <div>
              <COM_AutoComplte
                p_title="(#7.1) Select Registered User"
                p_data={regUserAll}
                p_Menu_ObjKey="user_login_name"
                p_selectedObj={selectedObj_u_coin}
                p_setSelectedObj={setSelectedObj_u_coin}
              />

              <div>{`user_login_name: '${selectedObj_u_coin.user_login_name}'`}</div>
              <div>{`user_login_uid: '${selectedObj_u_coin.user_login_uid}'`}</div>
              <div>{`user_login_email: '${selectedObj_u_coin.user_login_email}'`}</div>
              <img src={`${selectedObj_u_coin.user_profile_pic_url}`}></img>
            </div>

            <TextField
              fullWidth
              //autoFocus
              type="text"
              value={u_score}
              label="(#7.3) Add Score"
              placeholder=" Add Score"
              helperText="Add Score"
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: 20,
                  color: "#383838",
                  // backgroundColor: "black",
                },
              }}
              inputProps={{
                shrink: true,
                style: {
                  fontSize: 30,
                  color: "#1e8714",
                  // backgroundColor: "black",
                },
              }}
              onChange={(event) => {
                setU_score(event.target.value.replace(/[^0-9]/g, ""));
              }}
            />

            <Button
              onClick={() => {
                addDB_userScore(
                  selectedObj_u_coin.user_login_uid,
                  selectedObj_u_coin.user_login_email,
                  selectedObj_u_coin.user_login_name,
                  selectedObj_u_coin.user_profile_pic_url,
                  u_score,
                  `Manually added by - ${firebase_userInfo.email}`
                );
              }}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>
          {/* //----------------------------------------- Add User Score */}

          <div
            style={{
              display: "flex",
              backgroundColor: "#ffffff",
              padding: "50px 50px 50px 50px ",
            }}
          >
            <div>
              <COM_AutoComplte
                p_title="(#8.1) Select Registered User"
                p_data={regUserAll}
                p_Menu_ObjKey="user_login_name"
                p_selectedObj={selectedObj_u_coin}
                p_setSelectedObj={setSelectedObj_u_coin}
              />

              <div>{`user_login_name: '${selectedObj_u_coin.user_login_name}'`}</div>
              <div>{`user_login_uid: '${selectedObj_u_coin.user_login_uid}'`}</div>
              <div>{`user_login_email: '${selectedObj_u_coin.user_login_email}'`}</div>
              <img src={`${selectedObj_u_coin.user_profile_pic_url}`}></img>
            </div>

            <div>
              <TextField
                fullWidth
                //autoFocus
                type="text"
                value={u_coin}
                label="(#8.3) Add Coin"
                placeholder=" Add Coin"
                helperText="Add Coin"
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: 20,
                    color: "#383838",
                    // backgroundColor: "black",
                  },
                }}
                inputProps={{
                  shrink: true,
                  style: {
                    fontSize: 30,
                    color: "#1e8714",
                    // backgroundColor: "black",
                  },
                }}
                onChange={(event) => {
                  setU_coin(event.target.value.replace(/[^0-9]/g, ""));
                }}
              />
              {u_coin}
            </div>
            <Button
              onClick={() => {
                addDB_userCoin(
                  selectedObj_u_coin.user_login_uid,
                  selectedObj_u_coin.user_login_email,
                  selectedObj_u_coin.user_login_name,
                  selectedObj_u_coin.user_profile_pic_url,
                  u_coin,
                  "Manually added by "
                );
              }}
              style={{ color: "#1e8714", fontSize: "30px" }}
            >
              &#9658;
            </Button>
          </div>
          {/* //----------------------------------------- Add User Score */}
          {/* <div
        style={{
          display: "flex",
          backgroundColor: "#ffffff",
          padding: "50px 50px 50px 50px ",
        }}
      >
        <TextField
          fullWidth
          //autoFocus
          type="text"
          value={show_game_num}
          label="(#8) ScoreBoard show Game Num ?"
          placeholder=" ScoreBoard show Game Num ?"
          helperText="if you want show the 2nd game result on Scoreboard, key in 2"
          InputLabelProps={{
            shrink: true,
            style: {
              fontSize: 20,
              color: "#383838",
              // backgroundColor: "black",
            },
          }}
          inputProps={{
            shrink: true,
            style: {
              fontSize: 30,
              color: "#1e8714",
              // backgroundColor: "black",
            },
          }}
          onChange={(event) => {
            setShow_game_num(event.target.value.replace(/[^0-9]/g, ""));
          }}
        />

        <Button
          onClick={update_show_game_num}
          style={{ color: "#1e8714", fontSize: "30px" }}
        >
          &#9658;
        </Button>
      </div> */}
          <div>
            <Page_UserInfo />
          </div>
        </>
      ) : (
        <>NA</>
      )}
    </>
  );
}
