import React, { useState, useEffect } from "react";
import { orange, blueGrey } from "@material-ui/core/colors";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";

import Date_moment_Utils from "@date-io/moment"; // choose your lib
import "moment/locale/en-sg";

import moment from "moment";

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: "#2bc3ff",
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: orange["400"],
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: blueGrey.A700,
      },
      daySelected: {
        backgroundColor: blueGrey["400"],
      },
      dayDisabled: {
        color: blueGrey["100"],
      },
      current: {
        color: blueGrey["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: blueGrey["400"],
      },
    },
    // MuiPickersBasePicker: {
    //   pickerView: {
    //     backgroundColor: "black",
    //   },
    // },
  },
});

export default function X({ value2, onChange2, orientation, views }) {
  // ------------------------------------------------- Date Picker 2/2
  const [selectedDate1, set_selectedDate1] = useState(new Date());

  useEffect(() => {}, []);

  useEffect(() => {}, [selectedDate1]);

  moment.locale("fr");

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={Date_moment_Utils}
      locale={"en"} // zh-tw
    >
      <ThemeProvider theme={materialTheme}>
        {/* <DatePicker
          showTodayButton={true}
          orientation={orientation}
          variant="static"
          format="YYYY/MM/DD"
          label="REPORT DATE"
          views={views}
          minDate={new Date("2008-01-01")}
          // maxDate={new Date("2121-03-01")}
          value={selectedDate1}
          onChange={(date) => {
            onChange2(moment(date).format("YYYY-MM-DD"));
            set_selectedDate1(date);
          }}
        /> */}
        <DateTimePicker
          autoOk
          showTodayButton
          label="DateTimePicker"
          inputVariant="outlined"
          variant="static"
          value={selectedDate1}
          onChange={(date) => {
            onChange2(moment(date).format("YYYY-MM-DD HH:mm"));
            set_selectedDate1(date);
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
