import react, { useState, useEffect } from "react";
import axios from "axios";

// -------------------------------------------------  firebase - 1/2
import { authentication } from "../firebase-config";
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

// import { styled } from '@mui/material/styles';
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const {
  REACT_APP_ECLIPSE_API_PUBLIC,
  REACT_APP_ECLIPSE_API,
  REACT_APP_ECLIPSE_API_Local,
} = process.env;

export default function Page_UserProfile({
  registeredUserStatus,
  setRegisteredUserStatus,
}) {
  // ------------------------------------------------- DB
  const [totalScore, setTotalScore] = useState("");
  const getDB_userScore_total_byuid = (user_login_uid) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_score_total__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setTotalScore(res.data[0]);
        console.log("getDB_userScore_total_byuid", res.data[0]);
      })
      .catch((err) => {
        alert("Select Failed, " + err);
      });
  };

  const [totalCoin, setTotalCoin] = useState("");
  const getDB_userCoin_total_byuid = (user_login_uid) => {
    axios
      .get(
        `${REACT_APP_ECLIPSE_API}/get_eclipse_event__user_coin_total__byuid?user_login_uid=${user_login_uid}`
      )
      .then((res) => {
        setTotalCoin(res.data[0]);
        console.log("getDB_userCoin_total_byuid", res.data[0]);
      })
      .catch((err) => {
        alert("Select Failed, " + err);
      });
  };

  // -------------------------------------------------  firebase - 2/2
  const [firebase_userInfo, setFirebase_userInfo] = useState({});

  onAuthStateChanged(authentication, (currentUser) => {
    setFirebase_userInfo(currentUser);
  });

  useEffect(() => {
    getDB_userScore_total_byuid(firebase_userInfo.uid);
    getDB_userCoin_total_byuid(firebase_userInfo.uid);
  }, [firebase_userInfo]);

  return (
    <div>
      <div className="bg">
        <div className="userProfileOInfoContainer div_arrange_Horizontal ">
          <div className="div_arrange_Horizontal">
            <div>
              <div className="Score_ico">T</div>
            </div>
            <div style={{ color: "white" }}>{totalScore.total_u_score}</div>
            <div>
              <div className="Coin_ico">$</div>
            </div>
            <div style={{ color: "gold" }}>{totalCoin.total_u_coin}</div>
          </div>

          <img
            className="userProfileImg"
            src={
              firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ) != undefined
                ? firebase_userInfo?.providerData?.find(
                    (x) => x.providerId == "facebook.com"
                  ).photoURL
                : `https://avatars.dicebear.com/api/pixel-art/${firebase_userInfo?.uid}.svg`
            }
          />
          <div>
            <div>
              {firebase_userInfo?.providerData?.find(
                (x) => x.providerId == "facebook.com"
              ) != undefined
                ? firebase_userInfo?.providerData?.find(
                    (x) => x.providerId == "facebook.com"
                  ).displayName
                : "No Name"}
            </div>
            <div
              onClick={() => {
                signOut(authentication);
                setRegisteredUserStatus(false);
              }}
              className="userProfileLogoutBtn"
            >
              LOGOUT
            </div>
          </div>

          {/* <div>{firebase_userInfo?.email}</div> */}

          {/* <div style={{ color: "gray" }}>{firebase_userInfo?.uid}</div>
          <div style={{ color: "gray" }}>
            {registeredUserStatus ? "Registered User" : "Not yet Register"}
          </div> */}
        </div>
      </div>
    </div>
  );
}
